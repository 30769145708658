import StyledSpinner from './styles/Spinner';

const Spinner = () => {
  return (
    <StyledSpinner className="py-5">
      <div className="background">
        <div className="spinner">
          <div className="circle1 spinner__circle" />
          <div className="circle2 spinner__circle" />
          <div className="circle3 spinner__circle" />
          <div className="circle4 spinner__circle" />
          <div className="circle5 spinner__circle" />
          <div className="circle6 spinner__circle" />
          <div className="circle7 spinner__circle" />
          <div className="circle8 spinner__circle" />
          <div className="circle9 spinner__circle" />
          <div className="circle10 spinner__circle" />
          <div className="circle11 spinner__circle" />
          <div className="circle12 spinner__circle" />
        </div>
      </div>
    </StyledSpinner>
  );
};

export default Spinner;
