import dynamic from 'next/dynamic';
import useSWR from 'swr';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useGlobalContext from '@/hooks/useGlobalContext';
import Spinner from '@/components/Spinner';
import { getTransactionsCard } from '@/utils/cards';
import { fetcher } from '@/utils/api';
import { internalApiPaths } from '@/constants';
import { useTranslation } from '@/i18n';

const Header = dynamic(() => import('./Header'));
const Cards = dynamic(() => import('./Cards'));
const Account = dynamic(() => import('@/components/account/Account'));
const Error = dynamic(() => import('@/components/error/Error'));
const Transactions = dynamic(() => import('@/components/Transactions'));

const Dashboard = ({
  accountMe: {
    bitsa: { level },
  },
}) => {
  const { token } = useGlobalContext();
  const { t } = useTranslation(['dashboard', 'transactions', 'account']);
  const { data } = useSWR([internalApiPaths.CARDS_ALL, 'GET', token, null], fetcher);
  const result = useMemo(() => data && data.result && data.result, [data]);
  const historyCardTransactionList = useMemo(() => result && getTransactionsCard(result.cards), [result]);

  const renderContent = useMemo(() => {
    if (!result) {
      return null;
    }
    if (!result.hasBitsaAccount) {
      return <Account t={t} status="hasNotBitsaAccount" />;
    }
    if (result.isKycOnProcess || (result.kycData && result.kycData.kycIsOnValidation)) {
      return <Account t={t} status="isKycOnProcess" />;
    }
    if (!result.cards.length) {
      return <Account t={t} status="cardNotFound" />;
    }
    if (result.isBitsaWithIssues) {
      return <Error maintenance />;
    }

    return (
      <>
        <Header t={t} level={level} balance={result.bitsaGlobalBalance} retained={result.bitsaGlobalRetBalance} />
        <Cards t={t} cards={result.cards} accountProgram={result.bitsaAccountProgram} />
        <Transactions t={t} token={token} cardDetail={historyCardTransactionList} />
      </>
    );
  }, [result]);

  return !level || !result ? <Spinner /> : renderContent;
};

Dashboard.propTypes = {
  accountMe: PropTypes.shape({
    bitsa: PropTypes.shape({
      level: PropTypes.string,
    }),
  }).isRequired,
};

export default Dashboard;
