import { programTypes, cardSubTypes, cardPrograms } from '@/constants';

const getSubType = subType => cardSubTypes[subType];

const getAccountProgram = accountProgram =>
  cardPrograms.COMMON.includes(accountProgram) ? programTypes.COMMON : programTypes[accountProgram];

const getActiveCards = (type, status) => card => card.subtype === type && card.enabled && card.status === status;

const getCardProgram = cardProgram => programTypes[cardProgram];

const getCardImgPath = (accountProgram, subType, cardProgram) =>
  `${getAccountProgram(accountProgram)}/${getSubType(subType)}/${getCardProgram(cardProgram)}`;

const getDisabledCards = (type, status) => card => card.subtype === type && (!card.enabled || card.status === status);

const getEnabledCard = cards => cards.filter(element => element.enabled).shift();

const getTransactionsCard = cards =>
  cards.find(({ sharedBalance, mainCDE, pan, linkedToPan }) => sharedBalance && mainCDE && pan && linkedToPan) ||
  cards[0];

const slicePan = pan => pan && pan.slice(-6);

export {
  getAccountProgram,
  getActiveCards,
  getCardImgPath,
  getCardProgram,
  getDisabledCards,
  getEnabledCard,
  getSubType,
  getTransactionsCard,
  slicePan,
};
