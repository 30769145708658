import PropTypes from 'prop-types';
import Dashboard from '@/containers/Dashboard';
import { getToken, getLanguage } from '@/utils/cookies';

const DashboardPage = ({ accountMe }) => <Dashboard accountMe={accountMe} />;

DashboardPage.getInitialProps = ctx => ({ token: getToken(ctx), initialLanguage: getLanguage(ctx) });

DashboardPage.defaultProps = {
  accountMe: {
    bitsa: {
      level: '',
    },
  },
};

DashboardPage.propTypes = {
  accountMe: PropTypes.shape({}),
};

export default DashboardPage;
