import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import { spinner } from '@/styles/animations';

export default styled(Row)`
  left: 50%;
  z-index: -1;
  position: fixed;
  margin-top: 200px;
  transform: translate(-50%, -50%);

  .background {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/img/loader-bitsa.svg');
  }
  .spinner {
    width: 125px;
    height: 125px;
    position: relative;
    margin: 100px auto;
  }
  .spinner__circle {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .spinner__circle:before {
    ${spinner}
    content: '';
    width: 10%;
    height: 10%;
    display: block;
    margin: 140px auto;
    border-radius: 100%;
    background-color: var(--secundario);
  }
  .circle2 {
    transform: rotate(30deg);
  }
  .circle3 {
    transform: rotate(60deg);
  }
  .circle4 {
    transform: rotate(90deg);
  }
  .circle5 {
    transform: rotate(120deg);
  }
  .circle6 {
    transform: rotate(150deg);
  }
  .circle7 {
    transform: rotate(180deg);
  }
  .circle8 {
    transform: rotate(210deg);
  }
  .circle9 {
    transform: rotate(240deg);
  }
  .circle10 {
    transform: rotate(270deg);
  }
  .circle11 {
    transform: rotate(300deg);
  }
  .circle12 {
    transform: rotate(330deg);
  }
  .circle2:before {
    animation-delay: -1.1s;
  }
  .circle3:before {
    animation-delay: -1s;
  }
  .circle4:before {
    animation-delay: -0.9s;
  }
  .circle5:before {
    animation-delay: -0.8s;
  }
  .circle6:before {
    animation-delay: -0.7s;
  }
  .circle7:before {
    animation-delay: -0.6s;
  }
  .circle8:before {
    animation-delay: -0.5s;
  }
  .circle9:before {
    animation-delay: -0.4s;
  }
  .circle10:before {
    animation-delay: -0.3s;
  }
  .circle11:before {
    animation-delay: -0.2s;
  }
  .circle12:before {
    animation-delay: -0.1s;
  }
  @media (max-width: 768px) {
    margin-top: 140px;
  }
`;
